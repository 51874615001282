import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';


const swiper = new Swiper('.trust-content', {
  // Swiper options
  direction: 'horizontal', // Horizontal scrolling
  loop: true, // Infinite loop
  spaceBetween: 20, // Space between slides
  // slidesPerView: 'auto', // Allow auto adjustment of slide widths
  pagination: {
    el: '.swiper-pagination',
  },
  // Custom class names
  slideClass: 'trust_company',
  wrapperClass: 'trust_companies_wrapper',
  modules: [Pagination],
});


// Function to enable or disable swiping based on viewport width
function updateSwiperTouchMove() {
  // Check if viewport width is greater than 768 pixels
  if (window.matchMedia("(min-width: 768px)").matches) {
    // If yes, disable swiping
    swiper.allowTouchMove = false;
  } else {
    // If not, enable swiping
    swiper.allowTouchMove = true;
  }
}

// Call the function on initial load
updateSwiperTouchMove();

// Add event listener for window resize
window.addEventListener('resize', function () {
  updateSwiperTouchMove();
});

